import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/app/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_immediate_client_t556saLvNhyt_A6Pte2fzMGyWdkyhfaA5Fv_426OOe0 from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload-immediate.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_b5UNkRiOS3MV2QfaMwoZvpWEWDmuuq5PgrWgkUjIN3c from "/app/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin__pU5O5C_VbsaGGSmb4VRbMHXmeLBDloAvAuUQb8JGdU from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import analytics_client_xFVbgknZ_UZ_wzLosf7AeIWPGcfsltleZ3QS2qLdAzE from "/app/src/plugins/analytics.client.js";
import aos_J1UerlwAX6C8WBcF_06tBRAdM0GUFzB1MoyJdZ4k2HI from "/app/src/plugins/aos.js";
import confirm_qhuxgvIvU20oQevicvkvn5PkFwhUHPTTDzpvK_XI7OQ from "/app/src/plugins/confirm.js";
import dayjs_aMtJetpCUN2W86NsRzYV8Y6wH5Hy9PsHU6Y8KjUqDzk from "/app/src/plugins/dayjs.js";
import maska_LxERXW4PTwRKygXQhwM66Z0WR7zGAJsPxYfy23XW2sM from "/app/src/plugins/maska.js";
import recaptcha__trpW7UqY580E5Hk0T_CzFuSvgVD9m_qYBv6RQpPzv8 from "/app/src/plugins/recaptcha.js";
import toastify_3QFt46a1Rx_rmvzf8F831tQ46uw9u6QAmRM47wdzw1I from "/app/src/plugins/toastify.js";
import vuetify_g1sySFeXB6iJb1JMj1Dvdeb_ajJP9zZWO2WhtouFH9g from "/app/src/plugins/vuetify.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_immediate_client_t556saLvNhyt_A6Pte2fzMGyWdkyhfaA5Fv_426OOe0,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  session_client_b5UNkRiOS3MV2QfaMwoZvpWEWDmuuq5PgrWgkUjIN3c,
  plugin__pU5O5C_VbsaGGSmb4VRbMHXmeLBDloAvAuUQb8JGdU,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  analytics_client_xFVbgknZ_UZ_wzLosf7AeIWPGcfsltleZ3QS2qLdAzE,
  aos_J1UerlwAX6C8WBcF_06tBRAdM0GUFzB1MoyJdZ4k2HI,
  confirm_qhuxgvIvU20oQevicvkvn5PkFwhUHPTTDzpvK_XI7OQ,
  dayjs_aMtJetpCUN2W86NsRzYV8Y6wH5Hy9PsHU6Y8KjUqDzk,
  maska_LxERXW4PTwRKygXQhwM66Z0WR7zGAJsPxYfy23XW2sM,
  recaptcha__trpW7UqY580E5Hk0T_CzFuSvgVD9m_qYBv6RQpPzv8,
  toastify_3QFt46a1Rx_rmvzf8F831tQ46uw9u6QAmRM47wdzw1I,
  vuetify_g1sySFeXB6iJb1JMj1Dvdeb_ajJP9zZWO2WhtouFH9g
]