
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as givesNMHLoMUy1mFmAm3C6SFHSFFnP2kSsgkJDFj6YUulKYMeta } from "/app/src/pages/give.vue?macro=true";
import { default as indexiNtA_znKj_zn_45wsEgsmLpyYSVS7XVZ24_KIDRjqK_10Meta } from "/app/src/pages/index.vue?macro=true";
import { default as loginat_45I4W6dhaKeU4e7R3nRygvy7k1_45w8CiYRML3dJJNP8Meta } from "/app/src/pages/login.vue?macro=true";
import { default as contactcqrBMb0VR30gYuY3EAO2OrUdv9m4xRM33xSkk1jWttAMeta } from "/app/src/pages/contact.vue?macro=true";
import { default as privacyakcWNvqEvLVF7WLZtpDQBbvy7RmqweW7zSzLjIoLeNcMeta } from "/app/src/pages/privacy.vue?macro=true";
import { default as indexlj5oPrZFhmzgQIR_45l3OnuyR02DGp_yOhceborGLBuQoMeta } from "/app/src/pages/members/index.vue?macro=true";
import { default as index4r5qQIAr9Sjdq9eJ_45hPTJrRSE3XJuCZ6KCtkJOsRt1EMeta } from "/app/src/pages/members/users/index.vue?macro=true";
import { default as _91user_id_933eiLmIRf9Q0yenCbaDxDqbvdIaVsfjt84m4YB6Ig368Meta } from "/app/src/pages/members/users/[user_id].vue?macro=true";
import { default as usersskGG_gxfnoQ6zKjYLABXPZPL2Kc5uYfnPXVtUpv6BSQMeta } from "/app/src/pages/members/users.vue?macro=true";
import { default as password_45resetrjnIkAlXbWgXpgexE0uX5kVa63SXODr5U26YyES_45bVEMeta } from "/app/src/pages/password-reset.vue?macro=true";
import { default as our_45storyBulSo0XIU7QhX5p_63q6HK8tZRJVl6uQSYLkGKvFcYgMeta } from "/app/src/pages/about/our-story.vue?macro=true";
import { default as forgot_45passwordasyA5AhdHeJLWvhG31u0qjEc_ghZhv4zAHQEonMkuu0Meta } from "/app/src/pages/forgot-password.vue?macro=true";
import { default as next_45stepsi7emy1tBqBaYK_45HG5gfzAtmt3nlcG7c_TUi_45YQcMUMAMeta } from "/app/src/pages/faith/next-steps.vue?macro=true";
import { default as youthaU2ZwENxN4Ad9xJzthSXZM48IoXo72oft24egklNy_IMeta } from "/app/src/pages/ministries/youth.vue?macro=true";
import { default as my_45accountDSXdd2iq6DNQl35kR5Z8M2Qsb27Gu7FnLXRQ_2Ga3_4Meta } from "/app/src/pages/members/my-account.vue?macro=true";
import { default as children0oDU3pyv9JjbUaJWCQcJ_45MGrPh5YQe_j2FYWgFF_gS8Meta } from "/app/src/pages/ministries/children.vue?macro=true";
import { default as day_45campgDhHExpVB3Vm0F5GpKiAAUl7nU525PRV8O3KrcJK9igMeta } from "/app/src/pages/ministries/day-camp.vue?macro=true";
import { default as plan_45your_45visit9HBal9I89vX6onkoXNfFbHO0_45XaYIqojL_45fPjjphVGoMeta } from "/app/src/pages/about/plan-your-visit.vue?macro=true";
import { default as what_45we_45believeHX3NQMxKf6KCXCnhTG5cPAvfX48tsMplnXWY8915oBoMeta } from "/app/src/pages/about/what-we-believe.vue?macro=true";
import { default as index7O_ly5z1P_45v883QoFRqZqsYWmxzPzwHIL2CQecZR43EMeta } from "/app/src/pages/members/calendar/index.vue?macro=true";
import { default as discipleshipGRTBq7khWKYVPBDMpuS_wMNlN5iseVgc_mVGMQGjUN0Meta } from "/app/src/pages/ministries/discipleship.vue?macro=true";
import { default as what_45is_45the_45gospelsL5BCHcBhMPC95B8uAp1LAmaTVYAHRMPlMmH1FknjlcMeta } from "/app/src/pages/faith/what-is-the-gospel.vue?macro=true";
import { default as _91id_93nhB7j0XeD_45RbOMrI4YGPIASTKIirp0pm5700ytO1SccMeta } from "/app/src/pages/members/collections/[id].vue?macro=true";
import { default as indexbphC5R7CqPeZhC7CJBj7FYr0sPjgpsKgnsAPZMkqiDkMeta } from "/app/src/pages/members/collections/index.vue?macro=true";
export default [
  {
    name: "give",
    path: "/give",
    component: () => import("/app/src/pages/give.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/src/pages/login.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/src/pages/contact.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/app/src/pages/privacy.vue")
  },
  {
    name: "members",
    path: "/members",
    meta: indexlj5oPrZFhmzgQIR_45l3OnuyR02DGp_yOhceborGLBuQoMeta || {},
    component: () => import("/app/src/pages/members/index.vue")
  },
  {
    name: usersskGG_gxfnoQ6zKjYLABXPZPL2Kc5uYfnPXVtUpv6BSQMeta?.name,
    path: "/members/users",
    component: () => import("/app/src/pages/members/users.vue"),
    children: [
  {
    name: "members-users",
    path: "",
    meta: index4r5qQIAr9Sjdq9eJ_45hPTJrRSE3XJuCZ6KCtkJOsRt1EMeta || {},
    component: () => import("/app/src/pages/members/users/index.vue")
  },
  {
    name: "members-users-user_id",
    path: ":user_id()",
    meta: _91user_id_933eiLmIRf9Q0yenCbaDxDqbvdIaVsfjt84m4YB6Ig368Meta || {},
    component: () => import("/app/src/pages/members/users/[user_id].vue")
  }
]
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/app/src/pages/password-reset.vue")
  },
  {
    name: "about-our-story",
    path: "/about/our-story",
    component: () => import("/app/src/pages/about/our-story.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/app/src/pages/forgot-password.vue")
  },
  {
    name: "faith-next-steps",
    path: "/faith/next-steps",
    component: () => import("/app/src/pages/faith/next-steps.vue")
  },
  {
    name: "ministries-youth",
    path: "/ministries/youth",
    component: () => import("/app/src/pages/ministries/youth.vue")
  },
  {
    name: "members-my-account",
    path: "/members/my-account",
    meta: my_45accountDSXdd2iq6DNQl35kR5Z8M2Qsb27Gu7FnLXRQ_2Ga3_4Meta || {},
    component: () => import("/app/src/pages/members/my-account.vue")
  },
  {
    name: "ministries-children",
    path: "/ministries/children",
    component: () => import("/app/src/pages/ministries/children.vue")
  },
  {
    name: "ministries-day-camp",
    path: "/ministries/day-camp",
    component: () => import("/app/src/pages/ministries/day-camp.vue")
  },
  {
    name: "about-plan-your-visit",
    path: "/about/plan-your-visit",
    component: () => import("/app/src/pages/about/plan-your-visit.vue")
  },
  {
    name: "about-what-we-believe",
    path: "/about/what-we-believe",
    component: () => import("/app/src/pages/about/what-we-believe.vue")
  },
  {
    name: "members-calendar",
    path: "/members/calendar",
    meta: index7O_ly5z1P_45v883QoFRqZqsYWmxzPzwHIL2CQecZR43EMeta || {},
    component: () => import("/app/src/pages/members/calendar/index.vue")
  },
  {
    name: "ministries-discipleship",
    path: "/ministries/discipleship",
    component: () => import("/app/src/pages/ministries/discipleship.vue")
  },
  {
    name: "faith-what-is-the-gospel",
    path: "/faith/what-is-the-gospel",
    component: () => import("/app/src/pages/faith/what-is-the-gospel.vue")
  },
  {
    name: "members-collections-id",
    path: "/members/collections/:id()",
    meta: _91id_93nhB7j0XeD_45RbOMrI4YGPIASTKIirp0pm5700ytO1SccMeta || {},
    component: () => import("/app/src/pages/members/collections/[id].vue")
  },
  {
    name: "members-collections",
    path: "/members/collections",
    meta: indexbphC5R7CqPeZhC7CJBj7FYr0sPjgpsKgnsAPZMkqiDkMeta || {},
    component: () => import("/app/src/pages/members/collections/index.vue")
  }
]